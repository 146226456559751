<template>
    <div>
      <Card>
        <div class="baslik w-100">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            > 
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarNav">
              <ul class="navbar-nav d-flex justify-content-around w-100">
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-odemeler') }"
                >
                  <router-link class="nav-link" :to="{ path: 'odemeler' }"
                    >Ödemeler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-ozellikler') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'ozellikler' }"
                    >Özellikler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-genelozellikler') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'genelozellikler' }"
                    >Genel Özellikler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-resimler') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'resimler' }"
                    >Resimler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-etiketler') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'etiketler' }"
                    >Etiketler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-belgeler') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'belgeler' }"
                    >Belgeler</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  :class="{ active: isActive('mulk-gecmis') }"
                >
                  <router-link
                    class="nav-link"
                    :to="{ path: 'gecmis' }"
                    >Geçmiş</router-link
                  >
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <transition name="fade-in-up">
          <router-view></router-view>
        </transition>
      </Card>
    </div>
  </template>
  <script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from "vuex";
import { PROPERTY } from '../../core/services/store/property.module';
export default {
    name:"property-detail",
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mülk Detayı'},
    ]);
    let payload = {token : this.myUser, id :this.$route.params.id }
    this.$store.dispatch(PROPERTY,payload)
    

    },
    data(){
        return{
            tabActive:0
        }
    },

    methods:{
        isActive(routeName) {
            
            return this.$route.name === routeName;
        },
    },
    computed: {
    ...mapGetters(["myUser"]),
    },

   
    
}
</script>
  <style lang="scss" scoped>
  .nav-item{
    &.active{
      a{
        border-bottom: 2px solid $dveb-primary;
      }
    }
  }
  </style>